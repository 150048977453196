import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import styled from "styled-components"

import { adjustColorName } from "../../utils/color"
import Card from "../card"

const Container = styled.section`
  background-color: ${(props) =>
    props.theme[adjustColorName(props.backgroundColor)] ||
    props.theme.colorWhite};
  padding: ${rem(30)} 0;

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }
`
const Header = styled.div`
  ${(props) => props.theme.gridContainer()};

  text-align: center;
`

const Subtitle = styled.div.attrs({ className: `styled-h6` })`
  margin-bottom: ${rem(10)};
`

const Title = styled.h2.attrs({ className: `styled-h3` })`
  margin-bottom: ${rem(60)};
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: ${rem(-25)};
    width: ${rem(100)};
    height: 1px;
    background: ${(props) => props.theme.colorGreyLight};
    transform: translateX(-50%);
  }
`

const Cards = styled.div`
  ${(props) => props.theme.gridContainer()};
  ${(props) => props.theme.gridGrid()};
  ${(props) => props.theme.gridGridGutterX()};
  ${(props) => props.theme.gridGridGutterY()};

  > div {
    ${(props) => props.theme.gridCell(4)};

    display: flex;

    @media ${(props) => props.theme.mediumDown} {
      ${(props) => props.theme.gridCell(6)};
    }

    @media ${(props) => props.theme.xsmallDown} {
      ${(props) => props.theme.gridCell(12)};
    }
  }
`

const getNode = (nodes, node, showVariables) => {
  let typeNode = null

  if (node.__typename === `wordpress__PAGE`) {
    typeNode = {
      type: `page`,
      id: node.id,
      title: node.title,
      content: node.acf.intro,
      image: node.acf.image && node.acf.image.localFile.childImageSharp.fluid,
      imageAlt: node.acf.image && node.acf.image.altText,
      categories: node.categories,
      link: { url: node.path },
    }
  }

  if (node.__typename === `wordpress__POST`) {
    typeNode = {
      type: `post`,
      id: node.id,
      title: node.title,
      content: node.acf.intro || node.excerpt,
      image: node.acf.image && node.acf.image.localFile.childImageSharp.fluid,
      imageAlt: node.acf.image && node.acf.image.altText,
      categories: node.categories,
      link: { url: node.slug },
    }
  }

  if (typeNode) {
    return nodes.push({
      ...typeNode,
      ...showVariables,
    })
  }

  return null
}

const sortNodes = (nodes, sortBy, direction) => {
  const sortByX = sortBy === `published` ? `date` : sortBy
  const sortedNodes = [...nodes].sort((a, b) => {
    if (a.node[sortByX] < b.node[sortByX]) {
      return -1
    }
    if (a.node[sortByX] > b.node[sortByX]) {
      return 1
    }
    return 0
  })

  if (direction === `desc`) {
    return sortedNodes.reverse()
  }

  return sortedNodes
}

const filterNodesByCategories = (nodes, categories) => {
  const filteredNodes = [...nodes].filter(({ node }) => {
    let shouldBeShown = false
    if (node.categories) {
      node.categories.map(({ slug }) => {
        if (!shouldBeShown && categories.includes(slug)) {
          return (shouldBeShown = true)
        }
        return shouldBeShown
      })
    }
    return shouldBeShown ? node : null
  })
  return filteredNodes
}

const filterNodesByTags = (nodes, tags) => {
  const filteredNodes = [...nodes].filter(({ node }) => {
    let shouldBeShown = false
    if (node.tags) {
      node.tags.map(({ slug }) => {
        if (!shouldBeShown && tags.includes(slug)) {
          return (shouldBeShown = true)
        }
        return shouldBeShown
      })
    }
    return shouldBeShown ? node : null
  })
  return filteredNodes
}

const filterNodes = (nodes, categories, tags) => {
  let filterNodes = [...nodes]

  if (categories && categories.length >= 1) {
    filterNodes = filterNodesByCategories(filterNodes, categories)
  }

  if (tags && tags.length >= 1) {
    filterNodes = filterNodesByTags(filterNodes, tags)
  }

  return filterNodes
}

const ContentGrid = ({
  id,
  published,
  title,
  subTitle,
  categories,
  backgroundColor,
  numberOfItems,
  contentType,
  tags,
  sort,
  type,
  links,
  allPages,
  allPosts,
  wordpressId,
  showCategories,
  showExcerpts,
  showReadMore,
  showImages,
  showTitles,
}) => {
  if (!published) {
    return null
  }

  const mappedCategories = categories && categories.map(({ slug }) => slug)
  const mappedTags = tags && tags.map(({ slug }) => slug)
  const [sortType, sortDirection] = sort.split(`_`)

  const showVariables = {
    showTitles,
    showImages,
    showExcerpts,
    showCategories,
    showReadMore,
  }

  let nodes = []

  if (type === `children`) {
    const edges = allPages.edges.filter(({ node }) => {
      if (wordpressId === node.wordpressParent) {
        return node
      }

      return null
    })

    const filteredAndSortedEdges = sortNodes(
      filterNodes(edges, mappedCategories, mappedTags),
      sortType,
      sortDirection
    )

    filteredAndSortedEdges.map(({ node }) =>
      getNode(nodes, node, showVariables)
    )
  }

  if (type === `listing`) {
    let edges = []

    if (contentType.length === 1) {
      if (contentType.includes(`all`)) {
        edges = [...allPages.edges, ...allPosts.edges]
      }
      if (contentType.includes(`post`)) {
        edges = allPosts.edges
      }
      if (contentType.includes(`page`)) {
        edges = allPages.edges
      }
    } else {
      edges = [...allPages.edges, ...allPosts.edges]
    }

    const filteredAndSortedEdges = sortNodes(
      filterNodes(edges, mappedCategories, mappedTags),
      sortType,
      sortDirection
    )

    filteredAndSortedEdges.map(({ node }) =>
      getNode(nodes, node, showVariables)
    )
  }

  if (type === `custom` && links) {
    links.forEach((link) => {
      if (link.type === `page`) {
        const page = allPages.edges.find(
          ({ node }) => node.wordpressId === link.page.wordpressId
        )

        if (page) getNode(nodes, page.node, showVariables)
      } else {
        nodes.push({
          type: `custom`,
          id: link.title.replace(/[^a-zA-Z ]/g, ``) + Date.now(),
          title: link.title,
          content: link.excerpt,
          image: link.image && link.image.localFile.childImageSharp.fluid,
          imageAlt: link.image && link.image.altText,
          link: link.link,
          ...showVariables,
        })
      }
    })
  }

  if (numberOfItems !== `all`) {
    nodes = nodes.slice(0, parseInt(numberOfItems))
  }

  return (
    <Container backgroundColor={backgroundColor}>
      {(subTitle || title) && (
        <Header>
          {subTitle && (
            <Subtitle dangerouslySetInnerHTML={{ __html: subTitle }} />
          )}

          {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        </Header>
      )}

      {nodes.length > 0 && (
        <Cards>
          {nodes.map((node) => (
            <div key={`ContentGrid${id}${node.id}`}>
              <Card {...node} />
            </div>
          ))}
        </Cards>
      )}
    </Container>
  )
}

ContentGrid.propTypes = {
  id: PropTypes.string,
  published: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  numberOfItems: PropTypes.string,
  contentType: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  tags: PropTypes.array,
  sort: PropTypes.string.isRequired,
  categories: PropTypes.array,
  links: PropTypes.array,
  allPages: PropTypes.object.isRequired,
  allPosts: PropTypes.object.isRequired,
  wordpressId: PropTypes.number,
  showCategories: PropTypes.bool.isRequired,
  showTitles: PropTypes.bool.isRequired,
  showImages: PropTypes.bool.isRequired,
  showExcerpts: PropTypes.bool.isRequired,
  showReadMore: PropTypes.bool.isRequired,
}

export default ContentGrid
