/*
 * Converts the scss color to the javascript version
 * to be used with our theme.
 * example: green--light -> color.greenLight
 * ${props => props.theme.colorGreenLight}
 * ${props => props.theme[adjustColorName(`green--light`)]}
 * */
const adjustColorName = (color) => {
  const colorArray = color.split(`--`)

  return `color${colorArray
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(``)}`
}

export { adjustColorName }
