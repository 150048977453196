import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import Img from "gatsby-image"
import styled from "styled-components"

import Link from "../link"
import SvgSprite from "../svg-sprite"

const Container = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: ${(props) => props.theme.colorWhite};
  color: ${(props) => props.theme.colorBlueDark};
  border-radius: ${rem(5)};
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.05),
    0 0.3125rem 0.25rem 0 rgba(0, 0, 0, 0.05);
  padding: ${rem(30)};
  padding-bottom: ${rem(15)};
`

const Image = styled(Link)`
  .gatsby-image-wrapper {
    margin: ${rem(-30)};
    margin-bottom: ${rem(15)};

    > div {
      padding-bottom: 62.5% !important;
    }
  }
`

const Title = styled.h3`
  margin-bottom: ${rem(15)};
`

const Content = styled.div`
  flex-grow: 1;
`

const Footer = styled.div`
  margin-top: ${rem(15)};
  border-top: 1px solid ${(props) => props.theme.colorGreyLightest};

  a {
    margin-top: ${rem(20)};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: ${(props) => props.theme.colorBlue};
    font-weight: 600;
    cursor: pointer;

    svg {
      width: ${rem(22)};
      height: ${rem(22)};
      transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:hover {
      svg {
        transform: translateX(${rem(5)});
      }
    }
  }
`

const Categories = styled.div`
  margin-bottom: ${rem(15)};
  font-weight: 600;
  font-size: ${rem(12)};
  line-height: 1.3;

  a {
    text-transform: uppercase;
    color: #195393;

    &:hover {
      color: ${(props) => props.theme.colorBlue};
    }
  }
`

const Card = ({
  type,
  id,
  title,
  content,
  categories,
  link,
  image,
  imageAlt,
  showCategories,
  showExcerpts,
  showReadMore,
  showImages,
  showTitles,
}) => {
  const path = link ? link.url : `/`
  const dir = type === `post` ? `/artiklar/` : ``
  const target = link ? link.target : undefined

  return (
    <Container>
      {showImages && image && (
        <Image to={`${dir}${path}`} target={target}>
          <Img
            fluid={image}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={imageAlt}
          />
        </Image>
      )}

      {showTitles && (
        <Link to={`${dir}${path}`} target={target}>
          <Title
            className={`styled-h3`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
      )}

      {showCategories && categories && categories.length > 0 && (
        <Categories>
          {categories.map(({ name, slug }, index) => (
            <Link
              key={`${id}Category${index}`}
              to={`/kategori/${slug}`}
              target={target}
            >
              {name}
            </Link>
          ))}
        </Categories>
      )}

      {showExcerpts && content && (
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      )}

      {showReadMore && (
        <Footer>
          <Link to={`${dir}${path}`} target={target}>
            <span>Läs mer</span>

            <SvgSprite id={`arrow-right`} />
          </Link>
        </Footer>
      )}
    </Container>
  )
}

Card.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  categories: PropTypes.array,
  link: PropTypes.object,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  showCategories: PropTypes.bool,
  showTitles: PropTypes.bool,
  showImages: PropTypes.bool,
  showExcerpts: PropTypes.bool,
  showReadMore: PropTypes.bool,
}

export default Card
